export const adviserCognito = {
  cognitoUserPoolId: 'eu-west-1_vCtTlhWF3',
  cognitoIdentityPoolId: 'eu-west-1:a8cbeb2a-2658-427a-9ee6-87a7bdde112d',
  cognitoAppClientId: '6drqh80grhjp1p3u3o5t1cff20',
  cognitoUserPoolDomain: 'theexeter-adviser-uat.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
  cognitoScope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
};

export const collegueCognito = {
  cognitoUserPoolId: 'eu-west-1_SBB2v3dlF',
  cognitoIdentityPoolId: 'eu-west-1:a26d838b-a261-40b4-b65b-bf11663fbec6',
  cognitoAppClientId: 'q2a6q5c4m8p87pldr9lhrj1k3',
  cognitoUserPoolDomain: 'theexeter-colleague-uat.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
  cognitoScope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
};

export const registrationEnabled = true;
export const googleAnalyticsTag = '';
export const googleGtag = 'GTM-TFDQPZT4';
export const inactivityTimeout = '30'
export const authCookieExpiry = '60'

export default {};
